import React, { useContext } from "react"
import trattamentoPageStyles from "../../styles/slugPages/trattamento-page.module.scss"
import Img from "gatsby-image"
import FacebookIcon from "../../assets/facebook.svg"
import AddToCartIcon from "../../assets/addToCart.svg"
import Markdown from "react-markdown"
import { formatPrice } from "../../utilities/formatPrice"
import { StoreContext } from "../../context/storeContext"
import markdownStyles from "../../styles/common/markdown.module.scss"
import { isDateEarlier, formatDate } from "../../utilities/dateUtils"

const TrattamentoPage = ({ post, location }) => {
  const { addToCommittedCart } = useContext(StoreContext)
  const today = new Date().toLocaleDateString("it-IT", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })

  function LinkRenderer(props) {
    return (
      <a href={props.href} target="_blank">
        {props.children}
      </a>
    )
  }

  return (
    <>
      <div className={trattamentoPageStyles.post}>
        {post.promo &&
          isDateEarlier(formatDate(post.promo.inizio), today) &&
          isDateEarlier(today, formatDate(post.promo.fine)) && (
            <div className={trattamentoPageStyles.promo}>
            <span className={trattamentoPageStyles.promoText}>
              {post.promo.codice} sconto {post.promo.sconto}€
            </span>
            </div>
          )}
        <div className={trattamentoPageStyles.fotoBox}>
          <Img
            className={trattamentoPageStyles.foto}
            fluid={post.foto.childImageSharp.fluid}
            alt={`foto natura - ${post.foto.name}`}
          />
        </div>
        <h2 className={trattamentoPageStyles.title}>{post.nome}</h2>
        <div className={trattamentoPageStyles.header}>
          <span className={trattamentoPageStyles.category}>
            {post.categoria.replace(/_/g, " ")}
          </span>{" "}
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon className={trattamentoPageStyles.iconFacebook} />
          </a>
        </div>
        <div className={trattamentoPageStyles.header}>
          <span className={trattamentoPageStyles.price}>
            {formatPrice(post.prezzo)}
          </span>
          <span className={trattamentoPageStyles.duration}>{post.durata}</span>
        </div>
        <Markdown
          className={markdownStyles.testo}
          renderers={{ link: LinkRenderer }}
        >
          {post.descrizione}
        </Markdown>
        <button
          className={trattamentoPageStyles.cart}
          onClick={() => addToCommittedCart(post)}
        >
          <AddToCartIcon className={trattamentoPageStyles.iconCart} />
        </button>
      </div>
    </>
  )
}

export default TrattamentoPage
