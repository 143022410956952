import React from "react"
import Layout from "../components/common/layout"
import { graphql } from "gatsby"
import TrattamentoPage from "../components/slugPages/trattamentoPage"

const TrattamentoTemplate = ({ data, location }) => (
  <Layout
    pageMeta={{
      title: `${data.strapiTrattamento.nome}`,
      keywords: [`${data.strapiTrattamento.nome}`],
      description: `${data.strapiTrattamento.nome}`,
      link: `/trattamenti/${data.strapiTrattamento.slug}`,
    }}
    location={location}
  >
    <TrattamentoPage post={data.strapiTrattamento} location={location} />
  </Layout>
)
export default TrattamentoTemplate

export const postQuery = graphql`
  query TrattamentoQuery($slug: String!) {
    strapiTrattamento(slug: { eq: $slug }) {
      strapiId
      slug
      nome
      prezzo
      categoria
      durata
      descrizione
      promo {
        codice
        inizio
        fine
        sconto
      }
      foto {
        name
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`